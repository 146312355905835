import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import SignInOutForm from './SignInOutForm'; // Ensure this path is correct
import UsersListPage from './ListUsers';
import logo from './TTT_Logo.jpg'; 

function App() {
  const [currentView, setCurrentView] = useState('signIn');

  return (
    <div className="App">
      <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container">
          <a className="navbar-brand">
              <img src={logo} alt="Logo" width="30" height="30" className="d-inline-block align-top" />
              TTT Register
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <button className="btn btn-link nav-link" onClick={() => setCurrentView('signIn')}>Sign In</button>
                </li>
                <li className="nav-item">
                  <button className="btn btn-link nav-link" onClick={() => setCurrentView('usersList')}>Sign Out</button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <main className="container mt-4">
        {/* Conditional rendering based on the current view */}
        {currentView === 'signIn' ? <SignInOutForm /> : <UsersListPage />}
      </main>
    </div>
  );
}

export default App;