import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import 'bootstrap/dist/css/bootstrap.min.css';

// Validation schema
const SignInOutSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  course: Yup.string()
    .required('Required'),
});

const SignInOutForm = () => {
  const [isOperationSuccessful, setOperationSuccessful] = useState(false);

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    fetch('https://t4bn4brj3l.execute-api.eu-west-2.amazonaws.com/signin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        action: values.action,
        name: values.name,
        course: values.course,
        signinTime: new Date().toISOString(),
      }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      setOperationSuccessful(true);
      setTimeout(() => setOperationSuccessful(false), 2000);
    })
    .catch((error) => {
      console.error('Error:', error);
      alert('Error submitting form, please try again.'); // Replace this with error handling logic
    })
    .finally(() => {
      setSubmitting(false);
      resetForm();
    });
  };

  return (
    <div className="container">
      {isOperationSuccessful && <div className="alert alert-success">Operation successful!</div>}
      <Formik
        initialValues={{ name: '', course: '', action: '' }}
        validationSchema={SignInOutSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <div className="form-group row justify-content-center">
              <div className="col-sm-6 mb-3">
                <Field className="form-control" type="text" name="name" placeholder="Enter your name" />
                <ErrorMessage className="text-danger" name="name" component="div" />
              </div>
            </div>
            <div className="form-group row justify-content-center">
              <div className="col-sm-6 mb-3">
                <Field className="form-control" type="text" name="course" placeholder="Enter your course" />
              </div>
            </div>
            <div className="form-group row justify-content-center">
              <div className="col-sm-6">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() => setFieldValue('action', 'signIn')}
                >
                  Sign In
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignInOutForm;
