import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const UsersListPage = () => {
  const [users, setUsers] = useState([]);

  // Function to fetch signed-in users using the fetch API
  const fetchSignedInUsers = async () => {
    try {
      const response = await fetch('https://t4bn4brj3l.execute-api.eu-west-2.amazonaws.com/listusers');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Error fetching signed-in users:", error);
    }
  };

  // Function to handle sign-out using the fetch API
  const handleSignOut = async (PK) => {
    try {
      console.log(PK);
      const response = await fetch('https://t4bn4brj3l.execute-api.eu-west-2.amazonaws.com/signout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ PK }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Refresh the list of signed-in users to reflect the change
      fetchSignedInUsers();
    } catch (error) {
      console.error("Error signing out user:", error);
    }
  };

  // Fetch signed-in users on component mount
  useEffect(() => {
    fetchSignedInUsers();
  }, []);

  return (
    <div className="container">
      <h1>Signed-In Users</h1>
      <table className="table">
        <thead>
          <tr>
            <th>User Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={index}>
              <td>{user.name}</td>
              <td>
                <button className="btn btn-primary" onClick={() => handleSignOut(user.PK)}>Sign Out</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsersListPage;
